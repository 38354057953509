import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, url }) => {
  return (
    
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} title={title} />
        <div className="proj-txtx">
          <h4>
            { url === '#' ? (title) : <a href={url} target='_blank' rel="noreferrer">{title}</a>}
          </h4>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  )
}
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/hwan-dynasty-el-nido.jpg";
import projImg2 from "../assets/img/tarawis-el-nido-island-tours.jpg";
import projImg3 from "../assets/img/ausan-beach-front-cottages.jpg";
import projImg4 from "../assets/img/neoversal-trading.jpg";
import projImg5 from "../assets/img/mlm.jpg";
import projImg6 from "../assets/img/e-commmerce.jpg";
import projImg61 from "../assets/img/youtube.jpg";

import projImg7 from "../assets/img/microfinance.jpg";
import projImg8 from "../assets/img/pos.jpg";
import projImg9 from "../assets/img/kalayaan.jpg";

import projImg10 from "../assets/img/app-ecommerce.jpg";
import projImg11 from "../assets/img/delivery-app.jpg";
import projImg12 from "../assets/img/crypto-app.jpg";

import colorSharp2 from "../assets/img/color-sharp2.png";
//import colorSharp from "../assets/img/color-sharp3.png";
import 'animate.css';
//import TrackVisibility from 'react-on-screen';
import ScrollAnim from "./ScrollAnim";

export const Projects = () => {

  const projectsx = [
    {
      title: "Hwan Dynasty Suite",
      description: "Accommodation",
      imgUrl: projImg1,
      url: "https://hwandynastyelnido.com/",
    },
    {
      title: "Tarawis El Nido Island Tours",
      description: "Tours",
      imgUrl: projImg2,
      url: "https://tarawiselnidoislandtours.com/",
    },
    {
      title: "Ausan Beach Front Cottages & Restaurant",
      description: "Accommodation",
      imgUrl: projImg3,
      url: "https://ausanbeachfrontcottages.com/",
    },
    {
      title: "Neoversal Trading",
      description: "MLM",
      imgUrl: projImg4,
      url: "https://neoversaltrading.com",
    },
    {
      title: "Trading Reward",
      description: "MLM",
      imgUrl: projImg5,
      url: "#",
    },
    {
      title: "Mandy Mart",
      description: "E-Commerce",
      imgUrl: projImg6,
      url: "#",
    },
    {
      title: "Youtube Videos",
      description: "Videos",
      imgUrl: projImg61,
      url: "https://palawanfreelancer.com/videos/",
    },
  ];

  const projectsApp = [
    {
      title: "E-Commerce",
      description: "Mobile App",
      imgUrl: projImg10,
      url: "#",
    },
    {
      title: "Delivery App",
      description: "Mobile App",
      imgUrl: projImg11,
      url: "#",
    },
    {
      title: "Crypto App",
      description: "Mobile App",
      imgUrl: projImg12,
      url: "#",
    },
   
  ];

  const projectsSoftware = [
    {
      title: "Microfinance Software",
      description: "Software",
      imgUrl: projImg7,
      url: "#",
    },
    {
      title: "POS",
      description: "Software",
      imgUrl: projImg8,
      url: "#",
    },
    {
      title: "Employment Record System",
      description: "Software",
      imgUrl: projImg9,
      url: "#",
    },
   
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
           
              <div>
              <ScrollAnim animation="animate__slideInDown"><h2>Portfolio</h2></ScrollAnim>
              <ScrollAnim animation="animate__slideInUp"><p>Check out some of our recent projects:</p></ScrollAnim> 
                
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Web Design and Development</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Mobile Application</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Software</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" >
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projectsx.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                          {
                            projectsApp.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                  />
                              )
                            })
                          }
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          projectsSoftware.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        
        </Row>
       
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="" />
     
    </section>
  )
}
//import logo from './logo.svg';
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
// import { ServicesSection } from "./components/ServicesSection";
// import { ServiceDetails } from "./components/ServiceDetails";
import SkillsLanguages from "./components/SkillsLanguages";

//import { Helmet, HelmetProvider } from 'react-helmet-async';
//import ContactForm from './components/Contactme';
//import { Helmet } from "react-helmet";
//import cover from "./assets/img/palawan-freelancer-cover.jpg";
//import OGMeta from './components/OgMeta';
//import TitleBar from './components/TitleBar';

// import FirstSection from './components/test';
// import SecondSection from './components/test2';

function App() {
  return (
    <div className="App">
      {/* <HelmetProvider>
      <Helmet>
        <title>Web Developer in Palawan | Website Design and Development Services</title>
        <meta name="description" content="Professional web development services in Palawan. Specializing in responsive website design and custom web applications."/>
        <link rel='canonical' href='/' />
      </Helmet>
  </HelmetProvider> */}
      {/* <HelmetProvider>
  <Helmet>
  <title>Website Design and Development Services</title>
  <link rel="notImportant" href="https://palawanfreelancer.com" />
  <meta name="whatever" value="notImportant" />
  <link rel="canonical" href="https://palawanfreelancer.com" />
  <meta property="og:title" content="Web Design and Development Services"/>
</Helmet>
</HelmetProvider> */}

      {/* <TitleBar /> */}

      {/* <HelmetProvider>
        <Helmet>
        <title>Website Design and Development Services</title>
          <meta name="description" content="Professional web development services in Palawan. Specializing in responsive website design and custom web applications." />
          <meta name="keywords" content="web design Palawan,web developer Palawan, Software developer Palawan, Mobile app developer Palawan, Custom web design Palawan, Responsive web design Palawan, Professional web development Palawan, Custom software development Palawan, Mobile app development Palawan,Android app development Palawan" />
          <link rel='canonical' href='https://palawanfreelancer.com' />
        </Helmet> 
      </HelmetProvider>
      <OGMeta 
        title="Web Design and Development Services"
        type="website"
        image={cover}
        url="https://palawanfreelancer.com"
        description="Professionalx web development services in Palawan. Specializing in responsive website design and custom web applications. Multi-Level Marketing (MLM), E-Commerce, Mobile Application, Software"
      /> */}

      <NavBar />
      <Banner />
      <Skills />
      <SkillsLanguages />
      {/* <ServiceDetails />
      <ServicesSection /> */}

      {/* <FirstSection />
      <SecondSection /> */}
      <Projects />

      {/* <ContactForm /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/myAvatar.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import ScrollAnim from "./ScrollAnim";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <ScrollAnim animation="animate__fadeInLeft">
              {/* <img src={logo} alt="Logo" />  */}
            </ScrollAnim>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <ScrollAnim animation="animate__fadeInRight">
              <div className="social-icon">
                <a
                  href="https://www.linkedin.com/in/mario-roco-40b750aa/"
                  target={`_blank`}
                  rel="noreferrer"
                >
                  <img src={navIcon1} alt="Icon" />
                </a>
                <a
                  href="https://www.facebook.com/webdevblockchain"
                  target={`_blank`}
                  rel="noreferrer"
                >
                  <img src={navIcon2} alt="Icon" />
                </a>
                <a href="#/">
                  <img src={navIcon3} alt="Icon" />
                </a>
              </div>
              <p>Copyright {new Date().getFullYear()}. All Rights Reserved</p>
            </ScrollAnim>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import projImg1 from "../assets/img/programming/core-php.png";
import projImg2 from "../assets/img/programming/laravel.png";
import projImg3 from "../assets/img/programming/wordpress.png";
import projImg4 from "../assets/img/programming/react.png";
import projImg5 from "../assets/img/programming/mysql.png";
import projImg6 from "../assets/img/programming/javascript.png";
import projImg7 from "../assets/img/programming/jquery.png";
import projImg8 from "../assets/img/programming/flutter.png";
import projImg9 from "../assets/img/programming/android.png";
import projImg10 from "../assets/img/programming/csharp.png";
import projImg11 from "../assets/img/programming/vb.png";
import projImg12 from "../assets/img/programming/blockchain.png";
const SkillsLanguages = () => {
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}

      {/* slider */}
      <Swiper
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 2,
          },
          400: {
            width: 368,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            width: 368,
            slidesPerView: 2,
          },
        }}
        navigation
        spaceBetween={5}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={projImg1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg6} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg7} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg8} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg9} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg10} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg11} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={projImg12} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SkillsLanguages;

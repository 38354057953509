import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact.png";
// import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs from 'emailjs-com';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});


  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!formDetails.firstName) {
      formIsValid = false;
      tempErrors.firstName = 'First Name is required';
    }
    if (!formDetails.lastName) {
      formIsValid = false;
      tempErrors.lastName = 'Last Name is required';
    }

    if (!formDetails.phone) {
      formIsValid = false;
      tempErrors.phone = 'Phone no. is required';
    }

    if (!formDetails.email) {
      formIsValid = false;
      tempErrors.email = 'Email is required';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formDetails.email)) {
      formIsValid = false;
      tempErrors.email = 'Invalid email address';
    }

    if (!formDetails.message) {
      formIsValid = false;
      tempErrors.message =  'Message is required';//ErrorSuccess('Message is required');
    
    } else if (formDetails.message.length < 5){
      formIsValid = false;
      tempErrors.message = 'Message must has at least 6 characters';
    }



    setErrors(tempErrors);
    return formIsValid;
  };


  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }


  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!validateForm()) {
      console.log('Form validation failed!');
      return;
    }
    setButtonText("Sending...");
    //this.setState({ formStatus: { submitting: true } });
    try {
     //const { formData } = this.state;
     await emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, formDetails, process.env.REACT_APP_EMAILJS_USER_ID);
      //this.setState({ formStatus: { submitted: true, submitting: false } });
      setStatus({ success: true, message: 'Message sent successfully'});
      setFormDetails(formInitialDetails);
 
    } catch (error) {
      //this.setState({ formStatus: { error: true, submitting: false } });
      setStatus({ success: false, message: 'Something went wrong, please try again later.'});
    } finally{
      setButtonText("Send");
    }

    // e.preventDefault();
    // setButtonText("Sending...");
    // let response = await fetch("http://localhost:5000/contact", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json;charset=utf-8",
    //   },
    //   body: JSON.stringify(formDetails),
    // })
    // .catch(error =>  console.log(`edit click : ${error}`))
    // .finally(() => {
    //   setButtonText("Send");
    // });
  
    
    // let result = await response.json();
    // setFormDetails(formInitialDetails);
    // if (result.code === 200) {
    //   setStatus({ succes: true, message: 'Message sent successfully'});
    // } else {
    //   setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
    // }
  
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Get In Touch </h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                      {errors.firstName && <span className="danger">{errors.firstName}</span>}
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                      {errors.lastName && <span className="danger">{errors.lastName}</span>}
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                      {errors.email && <span className="danger">{errors.email}</span>}
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                      {errors.phone && <span className="danger">{errors.phone}</span>}
                    </Col>
                   
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      {errors.message && <span className="danger">{errors.message}</span>}

                      {
                      status.message &&
                      <Col size={12} sm={12} className="px-1">
                        <p className={(status.success === true) ? "success" : "danger"}>{status.message}</p>
                      </Col>
                      }

                      <div className="text-center">
                      <button type="submit"><span>{buttonText}</span></button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
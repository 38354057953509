import React, { useEffect } from 'react';

const ScrollAnim = ({ animation, children }) => {
  const sectionRef = React.useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(`${animation}`);
        } else {
          entry.target.classList.remove(`${animation}`);
        }
      },
      {
        threshold: [0]
      }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [animation]);

  return (
    <div className="animate__animated" ref={sectionRef}>
      {children}
    </div>
  );
};

export default ScrollAnim;
